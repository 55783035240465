import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import Project from './pages/Project';
import Projects from './pages/Projects';
import Analytics from './pages/Analytics';
import Payment from './pages/Payment';
import PaymentMethod from './pages/PaymentMethod';
 
const App = () => {
   return (
      <div className="flex min-h-screen w-full flex-col bg-muted/40">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/project" element={<Project />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/payment/:id" element={<Payment />} />
          <Route path="/payment/method/:id" element={<PaymentMethod />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
   );
};
 
export default App;