import { NavLink } from "react-router-dom";
import { Home, LineChart, Package, CircleUser, PanelLeft, Search, Users2, DollarSign, CalendarDays } from "lucide-react"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Progress } from "@/components/ui/progress";
import { Badge } from "./ui/badge";
import { FaCalendar, FaDollarSign, FaEye, FaMoneyBillWave, FaPeopleCarry, FaUser, FaUserFriends } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";


export default function ProjectCard(props: any) {

    return (
        <div className={`flex flex-col gap-1 ${props.className}`}>
            <Card className={`overflow-hidden group flex flex-col justify-items-end" ${props.full ? "h-full" : ""}`}>
                <CardHeader className={`flex justify-between space-y-0 p-0 relative ${props.full ? "h-full" : ""}`}>
                    <img
                        src={props.img || "/assets/ski.jpg"}
                        alt="projet"
                        className={`object-cover ${props.full ? "h-full w-full" : "h-52 w-full"}`}
                    />
                    <div className="flex gap-1 absolute p-1">
                        <Badge className="rounded font-normal" variant={"secondary"}>
                            34 days <FaCalendar className="ml-1" />
                        </Badge>
                        <Badge className="rounded font-normal" variant={"secondary"}>
                            45 <FaUserFriends className="ml-1" />
                        </Badge>
                    </div>
                    <div className={`hidden sm:flex ${props.showdesc ? "opacity-100" : "opacity-0"} group-hover:opacity-100 absolute ${props.full ? "h-full w-full" : "h-52 w-full"} flex flex-col justify-end transition-opacity ease-in-out duration-300`}>
                        <div className="mt-auto h-[50%] w-full whitespace-nowrap bg-gradient-to-t text-white from-black/70  flex flex-col justify-end p-4 pb-6">
                            <div className="flex gap-1 ">
                                <div className="flex-1 flex flex-col truncate">
                                    <span className="font-semibold text-sm truncate">
                                        {props.title || "No title"}
                                    </span>
                                    <span className="text-xs truncate">
                                        description de fou
                                    </span>
                                </div>
                                <div className="my-auto">
                                    <button className="bg-white rounded-full text-black p-3">
                                        <FaRegHeart className="w-3 h-3" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <CardContent className="flex sm:hidden flex-col gap-4 mt-4">
                    <div className="w-full flex gap-1 justify-end">
                        <div className="flex-1 flex flex-col truncate">
                            <span className="font-semibold text-sm truncate">
                                {props.title || "No title"}
                            </span>
                            <span className="text-xs truncate">
                                description de fou
                            </span>
                        </div>
                        <div className="my-auto">
                            <button className="bg-white rounded-full text-black p-3">
                                <FaRegHeart className="w-3 h-3" />
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-4 align-center">
                        <span className="flex text-xs gap-1">
                            <FaEye className="w-3 h-3 my-auto" />
                            3.4k
                        </span>
                        <div className=" flex-1 flex gap-3 align-center">
                            <Progress value={33} className="h-1.5 rounded-full my-auto" />
                            <span className="text-xs font-normal my-auto">33%</span>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className="hidden sm:flex gap-4 align-center mt-1">
                <span className="flex text-xs gap-1">
                    <FaEye className="w-3 h-3 my-auto" />
                    3.4k
                </span>
                <span className="flex text-xs gap-1">
                    <FaMoneyBillWave className="w-3 h-3 my-auto" />
                    1,300$
                </span>
                <div className=" flex-1 flex gap-3 align-center">
                    <Progress value={33} className="h-1.5 rounded-full my-auto" />
                    <span className="text-xs font-normal my-auto">33%</span>
                </div>
            </div>
        </div>
    );
};
